<template>
  <div class="bgImageStyles">
    <div
      class="d-flex justify-start text-h4 text-md-h1 text-start mb-10 pt-16 pl-10"
    >
      <label>
        <b class="secondary--text font-weight-black"
          >Firma electrónica
          <br />
          con validez jurídica.
          <br />
          <span class="noColor--text">Donde sea, cuando sea.</span></b
        >
      </label>
    </div>

    <div class="d-flex justify-start">
      <div class="d-flex flex-column pl-10">
        <div class="noColor--text text-sm-h4 text-md-h1 text-start">
          <v-icon
            class="noColor--text text-h4 text-md-h1 pb-2 font-weight-bold"
            alt="Ícono de Facebook"
          >
            mdi-facebook
          </v-icon>
          <label>
            /nexum.firma.electronica
          </label>
        </div>
        <div class="noColor--text text-sm-h4 text-md-h1">
          <v-icon
            class="noColor--text text-h4 text-md-h1 font-weight-bold"
            alt="Ícono de Instagram"
          >
            mdi-instagram
          </v-icon>
          <label>
            /nexum.legal
          </label>
        </div>
      </div>
    </div>

    <div class="d-flex justify-end py-16">
      <v-card
        color="noColor"
        height="130px"
        width="470px"
        :style="[
          $vuetify.breakpoint.xs
            ? { width: '200px', height: '70px' }
            : $vuetify.breakpoint.sm
            ? { width: '350px', height: '100px' }
            : { width: '470px', height: '130px' },
        ]"
        class="d-flex align-center roundedCard"
      >
        <img
          class="ml-6 ml-sm-10 ml-md-16 rounded-0"
          :style="[
            $vuetify.breakpoint.xs
              ? { width: '150px' }
              : $vuetify.breakpoint.sm
              ? { maxWidth: '250px' }
              : { width: '300px' },
          ]"
          :src="[require('@/assets/icons/LogoNexum.png')]"
          alt="Logo de Nexum"
        />
      </v-card>
    </div>
    <div class="noColor--text ml-10 pb-15">
      <label>© 2022 - 2023</label>
      <label
        class="cursor-pointer ml-8"
        alt="Ir al aviso de seguridad de Nexum"
        @click="handlePrivacyNoticeRoute"
      >
        Aviso de seguridad
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-13",
  data: () => ({}),
  computed: {},
  methods: {
    handlePrivacyNoticeRoute() {
      if (this.$router.currentRoute.name !== "privacy") {
        this.$router.push("aviso-de-privacidad");
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.bgImageStyles {
  background: url("../assets/background/FooterBackground.png") no-repeat;
  background-size: cover !important;
}
.roundedCard {
  border-radius: 85px 0 0 85px;
}
</style>
