var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-1 mt-sm-2 mb-4 rotateimg180"},[_c('v-card',{staticClass:"roundedCard borderRound",attrs:{"flat":"","color":"primary","width":_vm.$vuetify.breakpoint.xs ? 50 : _vm.$vuetify.breakpoint.sm ? 120 : 257,"height":_vm.$vuetify.breakpoint.xs ? 28 : _vm.$vuetify.breakpoint.sm ? 60 : 94}})],1),_c('div',{staticClass:"d-flex align-center justify-center mx-md-16 px-8 px-md-16"},[_c('img',{attrs:{"height":_vm.$vuetify.breakpoint.xs ? 40 : _vm.$vuetify.breakpoint.sm ? 60 : 120,"src":require("@/assets/icons/LegalDocument/check.png"),"alt":"Ícono de una marca de verificación"}}),_c('h2',{staticClass:"secondary--text font-weight-black px-6",class:_vm.$vuetify.breakpoint.xs
          ? 'text-h5'
          : _vm.$vuetify.breakpoint.sm
          ? 'text-h4'
          : _vm.$vuetify.breakpoint.md
          ? 'text-h3'
          : _vm.$vuetify.breakpoint.lg
          ? 'text-h2'
          : 'text-h1',style:(_vm.$vuetify.breakpoint.xs
          ? { maxWidth: '80%' }
          : _vm.$vuetify.breakpoint.sm
          ? { maxWidth: '70%' }
          : _vm.$vuetify.breakpoint.md
          ? { maxWidth: '60%' }
          : { maxWidth: '50%' })},[_vm._v(" Cada documento firmado con "),_c('span',{staticClass:"primary--text"},[_vm._v("Nexum")]),_vm._v(" cuenta con una total validez legal y certeza jurídica. ")])]),_c('div',{staticClass:"d-flex align-center mt-8 mt-sm-16 mb-4 mb-sm-5 pt-md-16 pb-sm-2 pb-md-6"},[_c('v-card',{staticClass:"roundedCard borderRound",style:(!_vm.$vuetify.breakpoint.xs &&
        !_vm.$vuetify.breakpoint.sm && { position: 'absolute' }),attrs:{"flat":"","color":"primary","width":_vm.$vuetify.breakpoint.xs ? 60 : _vm.$vuetify.breakpoint.sm ? 150 : '17%',"height":_vm.$vuetify.breakpoint.xs ? 28 : _vm.$vuetify.breakpoint.sm ? 60 : 94}}),_c('v-row',{staticClass:"justify-end justify-md-center"},[_c('v-col',{staticClass:"d-flex justify-end mr-sm-8 mr-xl-16 pr-5 pr-sm-8",attrs:{"cols":"3"}},[_c('img',{attrs:{"height":_vm.$vuetify.breakpoint.xs ? 22 : _vm.$vuetify.breakpoint.sm ? 45 : 80,"src":require("@/assets/icons/LegalDocument/se-logo.png"),"alt":"Logo de Secretaría de Economía"}})]),_c('v-col',{staticClass:"d-flex justify-start ml-sm-8 ml-xl-16 pl-5 pl-sm-10",attrs:{"cols":"3"}},[_c('img',{attrs:{"height":_vm.$vuetify.breakpoint.xs ? 22 : _vm.$vuetify.breakpoint.sm ? 45 : 93,"src":require("@/assets/icons/LegalDocument/nom-logo.png"),"alt":"Logo de Normas Oficiales Mexicanas"}})])],1)],1),_c('div',{staticClass:"pt-md-9"},[_c('v-card',{staticClass:"d-flex align-center roundedCard borderRound",attrs:{"flat":"","color":"primary","width":_vm.$vuetify.breakpoint.xs ? 130 : _vm.$vuetify.breakpoint.sm ? 300 : 655,"height":_vm.$vuetify.breakpoint.xs ? 28 : _vm.$vuetify.breakpoint.sm ? 60 : 94}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }