<template>
  <div class="pt-7 py-sm-16 pt-md-9 pb-md-0">
    <h2
      class="d-flex justify-center my-md-16 text-h4 text-md-h1 font-weight-black primary--text"
    >
      Beneficios
    </h2>
    <v-container
      class="d-flex flex-column flex-sm-row justify-space-around pt-md-5"
    >
      <div
        v-for="(item, idx) in dataArray"
        :key="idx"
        class="d-flex align-center justify-sm-start align-start align-sm-center flex-row flex-sm-column mt-5 pb-md-6 my-sm-0 px-3"
        :style="[
          $vuetify.breakpoint.xs ? { maxWidth: '100%' } : { maxWidth: '290x' },
        ]"
      >
        <img width="70px" :src="item.imgSource" :alt="item.imgAlt" />
        <div
          class="d-flex justify-center text-center mt-md-4 mb-md-16 px-4"
        >
          <label class="secondary--text text-body-1 text-md-subtitle-2">
            {{ item.text }}
          </label>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "benefits-component-05",
  data: () => ({
    dataArray: [
      {
        text: "Firma tus documentos desde cualquier lugar.",
        imgSource: [require("@/assets/icons/BenefitsComponent/pen-icon.png")],
        imgAlt: "Ícono de una pluma fuente en uso",
      },
      {
        text: "Evita enviar documentos por mensajería.",
        imgSource: [require("@/assets/icons/BenefitsComponent/dont-send.png")],
        imgAlt: "Ícono de libre de correspondencia física",
      },
      {
        text: "Mayor seguridad que la firma en papel.",
        imgSource: [require("@/assets/icons/BenefitsComponent/lock.png")],
        imgAlt: "Ícono de un candado",
      },
      {
        text: "Elementos de identidad imposibles de falsificar.",
        imgSource: [require("@/assets/icons/BenefitsComponent/falsify.png")],
        imgAlt: "Ícono de un documento firmado con una marca de rechazo",
      },
      {
        text: "Gestiona todos tus documentos fácilmente.",
        imgSource: [require("@/assets/icons/BenefitsComponent/document.png")],
        imgAlt: "Ícono de un documento con una marca de verificación",
      },
    ],
  }),
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
