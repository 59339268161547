<template>
  <v-container class="mt-5 mt-md-10 mb-16 pb-16 px-11">
    <div class="mt-md-16">
      <v-btn
        text
        plain
        x-small
        :ripple="false"
        class="d-flex align-center v-btn--active mt-16 my-md-16 pa-0"
        aria-label="Regresar a Inicio"
        @click="homePage"
      >
        <v-icon color="secondary" class="mr-1"> mdi-chevron-left </v-icon>
        <span class="text-body-1 text-md-subtitle-2"> Regresar </span>
      </v-btn>
      <div class="mt-10 py-md-4">
        <h1 class="text-h5 text-md-h1 font-weight-bold secondary--text mb-md-3">
          Aviso de privacidad
        </h1>
        <label class="text-body-1">
          Última actualización 01 de Enero, 2023
        </label>
      </div>

      <v-divider class="my-8 hidden-md-and-up" />

      <v-row class="mt-md-12">
        <v-col cols="12" md="8" :order="$vuetify.breakpoint.smAndDown ? 3 : 1">
          <p class="text-subtitle-2 text-md-h2 secondary--text pr-md-16 mb-12">
            En Nexum, respetamos y protegemos la privacidad de nuestros
            usuarios. Este aviso de privacidad describe cómo recopilamos,
            utilizamos, divulgamos y protegemos la información personal que
            obtenemos a través de nuestro sitio web.
          </p>

          <div id="first-notice-bullet">
            <h2
              class="text-h5 text-md-h2 font-weight-bold secondary--text mb-1"
            >
              1. DATOS PERSONALES QUE SERÁN TRATADOS
            </h2>
            <p class="text-body-1 text-md-h6 secondary--text">
              Para cumplir con las finalidades señaladas en el presente Aviso,
              ILUM, SOCIEDADES CONTROLADORAS, SUBSIDIARIAS O AFILIADAS BAJO EL
              CONTROL COMÚN DEL RESPONSABLE, O A UNA SOCIEDAD MATRIZ O A
              CUALQUIER SOCIEDAD DEL MISMO GRUPO recabará y tratará datos
              personales de identificación (incluyendo los derivados de sus
              propias características físicas); datos personales de contacto;
              datos patrimoniales; datos financieros; datos de seguros; datos de
              información comercial; datos de circunstancias sociales; datos
              académicos y profesionales; y, datos laborales. Se hace de su
              conocimiento que ILUM no tratará datos personales sensibles.
            </p>
          </div>

          <div class="mt-5" id="second-notice-bullet">
            <h2
              class="text-h5 text-md-h2 font-weight-bold secondary--text mb-1"
            >
              2. FINALIDADES PRIMARIAS DEL TRATAMIENTO DE DATOS PERSONALES
            </h2>
            <p class="text-body-1 text-md-h6 secondary--text">
              Los datos personales antes mencionados serán utilizados para las
              siguientes finalidades primarias y necesarias: Realizar actos de
              registro y aria-labela como cliente o prospecto de cliente;
              Operación y Gestión de los servicios que contrate con ILUM;
              Compartir información para proveer servicios de ILUM Evaluar la
              calidad del servicio brindado por ILUM.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Ofrecer, informar y prestar servicios y productos diversos, así
              como para el diseño, evaluación, adecuación y mejora de los
              mismos;
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Evaluación para la operación de los servicios que contrate con
              ILUM así como efectuar análisis de mercado, y de desempeño
              relacionados con nuestros servicios.
            </p>
          </div>

          <div class="mt-5" id="third-notice-bullet">
            <h2
              class="text-h5 text-md-h2 font-weight-bold secondary--text mb-1"
            >
              3. FINALIDADES SECUNDARIAS DEL TRATAMIENTO DE DATOS PERSONALES
            </h2>
            <p class="text-body-1 text-md-h6 secondary--text">
              Mantener todo tipo de comunicaciones vía telefónica, correo
              electrónico, mensajes de texto, llamadas telefónicas, redes
              sociales, fax, para fines mercadotécnicos, publicitarios o de
              prospección comercial.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              En caso de que usted no desee que sus datos personales sean
              tratados para las finalidades secundarias, desde este momento
              usted nos puede comunicar lo anterior contactando a nuestro
              Departamento de Privacidad al correo electrónico
              eduardo.herrera@ilumps.com ó carlos.garibay@ilumps.com.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Es importante mencionar que ILUM se compromete a tratar los datos
              personales única y exclusivamente para las finalidades aprobadas
              por el titular, así como establecer y mantener los controles de
              seguridad necesarios para la protección de los mismos.
            </p>
          </div>

          <div class="mt-5" id="fourth-notice-bullet">
            <h2
              class="text-h5 text-md-h2 font-weight-bold secondary--text mb-1"
            >
              4. MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE SUS DATOS
              PERSONALES
            </h2>
            <p class="text-body-1 text-md-h6 secondary--text">
              Usted podrá limitar el uso o divulgación de sus datos personales
              enviando su solicitud a ILUM a la dirección
              eduardo.herrera@ilumps.com ó carlos.garibay@ilumps.com señalando
              para cuales casos desea ser excluido y cuales datos. En caso de
              que su Solicitud sea procedente se le registrará en el listado de
              exclusión propio de ILUM.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Así mismo hacemos de su conocimiento que existen otros mecanismos
              para limitar el uso y divulgación de sus datos como es el Registro
              Público de Usuarios (REUS) de la CONDUSEF al cual puede acceder
              desde la página web de la institución mencionada.
            </p>
          </div>

          <div class="mt-5" id="fifth-notice-bullet">
            <h2
              class="text-h5 text-md-h2 font-weight-bold secondary--text mb-1"
            >
              5. MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN,
              CANCELACIÓN U OPOSICIÓN
            </h2>
            <p class="text-body-1 text-md-h6 secondary--text">
              Usted o su representante legal podrá ejercer cualquiera de los
              derechos de Acceso, Rectificación, Cancelación u Oposición (en lo
              sucesivo “Derechos ARCO”), enviando un correo electrónico a
              nuestro Departamento de Privacidad a la dirección electrónica
              eduardo.herrera@ilumps.com ó carlos.garibay@ilumps.com.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Para ejercer los derechos de Acceso, Rectificación, Cancelación y
              Oposición anteriormente descritos, usted o su representante legal
              deberá(n) acreditar su identidad y personalidad, para lo que es
              necesario que acompañe copia de alguna de las siguientes
              identificaciones vigentes: (i) Credencial del Instituto Federal
              Electoral o del Instituto Nacional Electoral; (ii) Pasaporte;
              (iii) Cartilla del Servicio Militar Nacional; (iv) Documento
              migratorio que constate la legal estancia en México.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              En caso de que no se acompañen los documentos de acreditación
              correspondientes o haya errores u omisiones en la solicitud y/o
              formato, dentro de los cinco (5) días hábiles siguientes a la
              recepción de la solicitud y/o formato, ILUM podrá requerirle que
              aporte los elementos o documentos necesarios para dar trámite a la
              misma y usted contará con diez (10) días hábiles para atender el
              requerimiento, contados a partir del día siguiente en que lo haya
              recibido. De no dar respuesta en dicho plazo, se tendrá por no
              presentada la solicitud correspondiente.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Salvo que usted expresamente señale que desea recibir respuesta
              por un medio diverso, ILUM responderá a su solicitud a través del
              correo electrónico proporcionado en su solicitud en un plazo
              máximo de 20 (veinte) días hábiles, contados desde la fecha en que
              se recibió la solicitud de acceso, rectificación, cancelación u
              oposición. De resultar procedente lo solicitado, se hará efectivo
              dentro de los 15 (quince) días hábiles siguientes a la fecha en
              que se le comunique la respuesta. Tratándose de solicitudes de
              acceso a datos personales, ILUM procederá con la entrega, previa
              acreditación de la identidad del solicitante o de su representante
              legal, según corresponda.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Los plazos antes referidos podrán ser ampliados una sola vez por
              un periodo igual, siempre y cuando así lo justifiquen las
              circunstancias del caso.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              El formato de solicitud de Derechos Arco lo puede solicitar a la
              dirección electrónica eduardo.herrera@ilumps.com ó
              carlos.garibay@ilumps.com el cual deberá de estar debidamente
              completado.
            </p>
          </div>

          <div class="mt-5" id="sixth-notice-bullet">
            <h2
              class="text-h5 text-md-h2 font-weight-bold secondary--text mb-1"
            >
              6. MECANISMOS Y PROCEDIMIENTOS DE REVOCACIÓN DEL CONSENTIMIENTO
            </h2>
            <p class="text-body-1 text-md-h6 secondary--text">
              Usted o su representante legal podrán revocar su consentimiento
              para el tratamiento de sus datos siguiendo el procedimiento que se
              detalla a continuación:
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Para revocar su consentimiento, usted o su representante legal
              deberá(n) acreditar su identidad y personalidad, para lo que es
              necesario que acompañe copia de alguna de las siguientes
              identificaciones vigentes: (i) Credencial del Instituto Federal
              Electoral o del Instituto Nacional Electoral; (ii) Pasaporte;
              (iii) Cartilla del Servicio Militar Nacional; (iv) Documento
              migratorio que constate la legal estancia en México; (v) documento
              que acredite la personalidad del representante legal de
              conformidad con la legislación mexicana.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Así mismo, deberá(n) presentar solicitud en términos de lo
              siguiente:
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Es importante que señale con precisión si desea revocar su
              consentimiento de forma total o parcial y deberá proporcionar los
              datos que permitan su identificación dentro de las bases de datos
              de ILUM, siempre y cuando dicha revocación no suponga la
              imposibilidad de cumplir obligaciones derivadas de una relación
              entre usted y ILUM.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              En caso de que no se acompañen los documentos de acreditación
              correspondientes o haya errores u omisiones en la solicitud,
              dentro de los cinco (5) días hábiles siguientes a la recepción de
              la solicitud, ILUM, SOCIEDADES CONTROLADORAS, SUBSIDIARIAS O
              AFILIADAS BAJO EL CONTROL COMÚN DEL RESPONSABLE, O A UNA SOCIEDAD
              MATRIZ O A CUALQUIER SOCIEDAD DEL MISMO GRUPO podrá requerirle que
              aporte los elementos o documentos necesarios para dar trámite a la
              misma y usted contará con diez (10) días hábiles para atender el
              requerimiento, contados a partir del día siguiente en que lo haya
              recibido. De no dar respuesta en dicho plazo, se tendrá por no
              presentada la solicitud correspondiente.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              ILUM dará respuesta a su solicitud por correo electrónico en un
              plazo máximo de (20) días.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Usted podrá revocar su consentimiento enviando su solicitud a
              ILUM, SOCIEDADES CONTROLADORAS, SUBSIDIARIAS O AFILIADAS BAJO EL
              CONTROL COMÚN DEL RESPONSABLE, O A UNA SOCIEDAD MATRIZ O A
              CUALQUIER SOCIEDAD DEL MISMO GRUPO a la dirección
              eduardo.herrera@ilumps.com ó carlos.garibay@ilumps.com.
            </p>
          </div>

          <div class="mt-5" id="seventh-notice-bullet">
            <h2
              class="text-h5 text-md-h2 font-weight-bold secondary--text mb-1"
            >
              7. DERECHO DE ACUDIR AL INSTITUTO NACIONAL DE TRANSPARENCIA,
              ACCESO A LA INFORMACIÓN Y PROTECCIÓN DE DATOS PERSONALES (INAI)
            </h2>
            <p class="text-body-1 text-md-h6 secondary--text">
              ILUM le informa, que tiene derecho de acudir al INAI en caso de
              considerar que ha sido vulnerado su derecho a la protección de
              datos personales.
            </p>
          </div>

          <div div class="mt-5" id="eighth-notice-bullet">
            <h2
              class="text-h5 text-md-h2 font-weight-bold secondary--text mb-1"
            >
              8. TRANSFERENCIA DE DATOS PERSONALES
            </h2>
            <p class="text-body-1 text-md-h6 secondary--text">
              ILUM, SOCIEDADES CONTROLADORAS, SUBSIDIARIAS O AFILIADAS BAJO EL
              CONTROL COMÚN DEL RESPONSABLE, O A UNA SOCIEDAD MATRIZ O A
              CUALQUIER SOCIEDAD DEL MISMO GRUPO no transferirá sus datos
              personales a ningún tercero, salvo que la normatividad o las
              autoridades competentes así lo exijan y en términos de lo
              siguiente:
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              ILUM, SOCIEDADES CONTROLADORAS, SUBSIDIARIAS O AFILIADAS BAJO EL
              CONTROL COMÚN DEL RESPONSABLE, O A UNA SOCIEDAD MATRIZ O A
              CUALQUIER SOCIEDAD DEL MISMO GRUPO podrá transferir sus datos
              personales a su sociedad controladora, subsidiarias, afiliadas o a
              cualquier sociedad que forme parte del mismo grupo empresarial al
              que pertenece ILUM, SOCIEDADES CONTROLADORAS, SUBSIDIARIAS O
              AFILIADAS BAJO EL CONTROL COMÚN DEL RESPONSABLE, O A UNA SOCIEDAD
              MATRIZ O A CUALQUIER SOCIEDAD DEL MISMO GRUPO y que opere bajo los
              mismos procesos y políticas internas.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Así mismo, podrá trasferir sus datos personales cuando la
              transferencia sea precisa para el mantenimiento o cumplimiento de
              una relación jurídica entre ILUM y el titular.
            </p>
          </div>

          <div div class="mt-5" id="nineth-notice-bullet">
            <h2
              class="text-h5 text-md-h2 font-weight-bold secondary--text mb-1"
            >
              9. DATOS CONTACTO DEPARTAMENTO DE PRIVACIDAD
            </h2>
            <p class="text-body-1 text-md-h6 secondary--text">
              Responsable de Privacidad: Eduardo Herrera
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Dirección: Kansas 2000, Campestre - Lomas, 31214, Chihuahua,
              Chih., México.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Correo electrónico: eduardo.herrera@ilumps.com
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Teléfono: 6141573761
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Horario de Atención de 9:00 A.M. a 6:00P.M de lunes a jueves.
            </p>
          </div>

          <div div class="mt-5" id="tenth-notice-bullet">
            <h2
              class="text-h5 text-md-h2 font-weight-bold secondary--text mb-1"
            >
              10. CAMBIOS AL AVISO DE PRIVACIDAD
            </h2>
            <p class="text-body-1 text-md-h6 secondary--text">
              ILUM se reserva el derecho, bajo su exclusiva discreción, de
              cambiar, modificar, agregar o eliminar partes del presente Aviso
              de Privacidad en cualquier momento. En tal caso, ILUM publicará
              dichas modificaciones en su página de internet e indicará la
              última versión del aviso. Le recomendamos visitar periódicamente
              esta página con la finalidad de informarse si ocurre algún cambio
              al presente.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              EN SU CASO, EL USO DE DIRECCIONES IP, COOKIES, WEB BEACONS Y
              CUALQUIER OTRA TECNOLOGÍA Le informamos que en algunas de nuestras
              páginas de internet utilizamos cookies y otras tecnologías, a
              través de las cuales es posible monitorear el comportamiento como
              usuario de internet, para brindarle un mejor servicio y
              experiencia al navegar en nuestra página. Los datos personales que
              recabamos a través de estas tecnologías, los utilizaremos para los
              siguientes fines: administrar, operar y monitorear los servicios
              que solicitó con ILUM y para verificar su identidad. Los datos
              personales que se pueden obtener a través del uso de estas
              tecnologías son los siguientes identificadores: ID de usuario, ID
              de sesión, región desde donde se accede, tipo de navegador, tipo
              de sistema operativo; fecha y hora del inicio y final de una
              sesión; páginas web visitadas, búsquedas realizadas y publicidad
              revisada.
            </p>
            <p class="text-body-1 text-md-h6 secondary--text">
              Estas tecnologías podrán deshabilitarse siguiendo los
              procedimientos del navegador de internet que utiliza, sin embargo,
              se debe tener en cuenta que esto podría provocar que no sea capaz
              de obtener todo el funcionamiento que este sitio web ofrece.
              Adicionalmente puede detener la transmisión de información a
              través de estas cookies siguiendo el procedimiento que su
              navegador le indique.
            </p>
          </div>

          <div div class="mt-5" id="eleventh-notice-bullet">
            <h2
              class="text-h5 text-md-h2 font-weight-bold secondary--text mb-1"
            >
              11. ACEPTACIÓN DE TÉRMINOS
            </h2>
            <p class="text-body-1 text-md-h6 secondary--text">
              Esta declaración de Privacidad constituye un acuerdo legal entre
              el usuario e ILUM. Si utiliza nuestros servicios, significa que ha
              leído, entendido y consentido los términos antes expuestos.
            </p>
          </div>
        </v-col>
        <v-col cols="0" md="1" order="2" />
        <v-col cols="0" md="3" :order="$vuetify.breakpoint.smAndDown ? 1 : 3">
          <h3
            class="text-h5 text-md-h2 font-weight-bold secondary--text text-md-center mb-6"
          >
            Tabla de contenidos
          </h3>
          <div class="text-body-1 text-md-subtitle-1 secondary--text">
            <ol class="text-decoration-underline">
              <li
                class="cursor-pointer"
                aria-label="Ir a la sección de datos personales que serán tratados"
                @click="handleScrollPrivacyNotice('first-notice-bullet')"
              >
                DATOS PERSONALES QUE SERÁN TRATADOS
              </li>
              <li
                class="cursor-pointer mt-2"
                aria-label="Ir a la sección de finalidades primarias del tratamiento de datos personales"
                @click="handleScrollPrivacyNotice('second-notice-bullet')"
              >
                FINALIDADES PRIMARIAS DEL TRATAMIENTO DE DATOS PERSONALES
              </li>
              <li
                class="cursor-pointer mt-2"
                aria-label="Ir a la sección de finalidades secundarias del tratamiento de datos personales"
                @click="handleScrollPrivacyNotice('third-notice-bullet')"
              >
                FINALIDADES SECUNDARIAS DEL TRATAMIENTO DE DATOS PERSONALES
              </li>
              <li
                class="cursor-pointer mt-2"
                aria-label="Ir a la sección de medios para limitar el uso o divulgación de sus datos personales"
                @click="handleScrollPrivacyNotice('fourth-notice-bullet')"
              >
                MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE SUS DATOS PERSONALES
              </li>
              <li
                class="cursor-pointer mt-2"
                aria-label="Ir a la sección de medios para ejercer los derechos de acceso, rectificación, cancelación u oposición"
                @click="handleScrollPrivacyNotice('fifth-notice-bullet')"
              >
                MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN,
                CANCELACIÓN U OPOSICIÓN
              </li>
              <li
                class="cursor-pointer mt-2"
                aria-label="Ir a la sección de mecanismos y procedimientos de revocación del consentimiento"
                @click="handleScrollPrivacyNotice('sixth-notice-bullet')"
              >
                MECANISMOS Y PROCEDIMIENTOS DE REVOCACIÓN DEL CONSENTIMIENTO
              </li>
              <li
                class="cursor-pointer mt-2"
                aria-label="Ir a la sección de derecho de acudir al instituto nacional de transparencia, acceso a la información y protección de datos personales (INAI)"
                @click="handleScrollPrivacyNotice('seventh-notice-bullet')"
              >
                DERECHO DE ACUDIR AL INSTITUTO NACIONAL DE TRANSPARENCIA, ACCESO
                A LA INFORMACIÓN Y PROTECCIÓN DE DATOS PERSONALES (INAI)
              </li>
              <li
                class="cursor-pointer mt-2"
                aria-label="Ir a la sección de transferencia de datos personales"
                @click="handleScrollPrivacyNotice('eighth-notice-bullet')"
              >
                TRANSFERENCIA DE DATOS PERSONALES
              </li>
              <li
                class="cursor-pointer mt-2"
                aria-label="Ir a la sección de datos contacto departamento de privacidad"
                @click="handleScrollPrivacyNotice('nineth-notice-bullet')"
              >
                DATOS CONTACTO DEPARTAMENTO DE PRIVACIDAD
              </li>
              <li
                class="cursor-pointer mt-2"
                aria-label="Ir a la sección de cambios al aviso de privacidad"
                @click="handleScrollPrivacyNotice('tenth-notice-bullet')"
              >
                CAMBIOS AL AVISO DE PRIVACIDAD
              </li>
              <li
                class="cursor-pointer mt-2"
                aria-label="Ir a la sección de aceptación de términos"
                @click="handleScrollPrivacyNotice('eleventh-notice-bullet')"
              >
                ACEPTACIÓN DE TÉRMINOS
              </li>
            </ol>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "PrivacyNotice",
  methods: {
    handleScrollPrivacyNotice(id) {
      let element = document.getElementById(id);
      let headerOffset = 100;
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
    homePage() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>
