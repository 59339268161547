import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#f77417",
        primaryHover: "#b15c00",
        primaryPress: "#854500",
        secondary: "#011D3F",
        secondaryPress: "#010f20",
        noColor: "#FFFFFF",
        grayColor: "#ececec",
      },
    },
  },
});
