<template>
  <v-card fluid class="bgImageStyles mt-0 pt-0 rounded-0" elevation="1">
    <div class="text-h4 text-md-h1 noColor--text text-center font-weight-black">
      <div class="pt-16">
        <h2 class="text-h4 text-md-h1 noColor--text font-weight-black">
          Testimoniales
        </h2>
      </div>

      <div>
        <div class="d-flex flex-row flex-wrap justify-space-around px-6">
          <v-slide-group center-active dark draggable="true">
            <template v-slot:next>
              <v-icon color="primary" large>mdi-menu-right</v-icon>
            </template>
            <template v-slot:prev>
              <v-icon color="primary" large>mdi-menu-left</v-icon>
            </template>
            <v-slide-item v-for="items in cardDataArray" :key="items.title">
              <v-card
                color="transparent"
                background-color="grey lighten-1"
                class="py-10 ma-5 px-5 mb-6 rounded-md"
                elevation="0"
                width="280px"
              >
                <div>
                  <img
                    :src="items.imgSource"
                    width="100px"
                    height="100px"
                    class="rounded-circle"
                  />
                </div>
                <div
                  class="my-2 text-center"
                >
                  <h3 class="primary--text font-weight-bold"
                    :class="
                      $vuetify.breakpoint.xs
                        ? 'text-h4'
                        : $vuetify.breakpoint.sm
                        ? 'text-h3'
                        : 'text-subtitle-0'
                    "                  
                  >
                    {{ items.name }}
                  </h3>
                </div>
                <div class="noColor--text my-2 text-body-1 text-md-subtitle-1">
                  <label>
                    {{ items.position }}
                  </label>
                </div>
                <div
                  class="noColor--text my-2 text-h4 text-md-h3 font-weight-bold"
                >
                  <label>
                    {{ items.business }}
                  </label>
                </div>
                <div>
                  <v-icon
                    v-for="dots in items.starRating"
                    :key="dots + 1"
                    color="primary"
                    >mdi-star
                  </v-icon>
                </div>
                <div
                  class="noColor--text my-2 text-body-1 text-md-subtitle-1 text-center"
                >
                  {{ items.comment }}
                </div>
              </v-card>
            </v-slide-item>
          </v-slide-group>
          <div class="mb-5 hidden-md-and-up">
            <label class="text-body-1">
              Desliza para ver más
            </label>
            <v-icon class="noColor--text">mdi-menu-right</v-icon>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "reviews-09.vue",
  data: () => ({
    cardDataArray: [
      {
        imgSource: [require("@/assets/icons/reviews/EduardoHerrera.png")],
        name: "Eduardo Herrera",
        position: "Sherpa Legal",
        business: "Ilum",
        starRating: 5,
        comment:
          "“Agilizamos la firma de documentos de la operación diaria, reduciendo los tiempos de espera, liberando tiempo para enfocarnos en nuevos procesos“",
      },
      {
        imgSource: [require("@/assets/icons/reviews/BereniceGnz.png")],
        name: "Berenice González ",
        position: "Fund Manager",
        business: "Zuma Capital",
        starRating: 5,
        comment:
          "“Esta plataforma nos ha permitido eficientar más nuestros recursos al poder firmar de manera remota nuestras Actas de Asamblea, impactando el tiempo y costo, ya que muchos de nuestros socios se encuentran en distintas partes de México.”",
      },
      {
        imgSource: [require("@/assets/icons/reviews/LizJimenez.png")],
        name: "Lizette Jiménez",
        position: "Startups Manager",
        business: "Balero Ventures",
        starRating: 5,
        comment:
          "“Nexum ha sido una herramienta de suma relevancia para Balero, para tener nuestros documentos en orden y firmados de una  manera rápida y segura. Sin duda alguna hemos podido acortar tiempos de espera para el avance de nuestra empresa.”",
      },
    ],
  }),
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.bgImageStyles {
  background: url("../assets/background/ReviewsBackground.png") no-repeat;
  background-size: cover !important;
}
</style>
