<template>
  <v-card flat class="bgImageStyles rounded-0 pt-md-4 pb-md-16 px-16">
    <h2
      class="text-center text-h4 text-md-h1 font-weight-black noColor--text mb-12 mb-md-16 pt-10 pt-md-16"
    >
      Lista de <span class="secondary--text">precios</span>
    </h2>
    <v-row class="mb-16 pb-15 pb-md-10 mx-1 mx-md-0">
      <v-col cols="12" md="6" class="mb-4 mb-md-0">
        <div class="d-flex align-center mb-4">
          <div
            class="text-h4 text-md-h6 font-weight-regular noColor--text mr-6"
          >
            ¿Cuántas firmas necesitas?
          </div>
          <v-btn
            outlined
            color="secondary"
            class="hidden-sm-and-down text-h5 text-md-h4 font-weight-black rounded-md"
            aria-label="Redirigir a registro de la plataforma Nexum"
            @click="handleRedirect()"
          >
            Contratar
          </v-btn>
        </div>
        <div>
          <v-sheet
            v-for="(price, i) in prices"
            :key="i"
            :height="$vuetify.breakpoint.xs ? 36 : 59"
            :width="
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? '100%'
                : 100 - (10 - i * 3.3) + '%'
            "
            color="transparent"
            class="border-card d-flex justify-space-between align-center text-h5 text-sm-h2 font-weight-black noColor--text rounded-md my-4 py-0 px-5"
          >
            <span>{{ price.range }}</span>
            <span>${{ price.cost }}</span>
          </v-sheet>
        </div>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-center align-center">
        <v-card
          :flat="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
          :elevation="8"
          :height="
            $vuetify.breakpoint.xs ? 137 : $vuetify.breakpoint.sm ? 250 : 378
          "
          :width="
            $vuetify.breakpoint.xs ? 130 : $vuetify.breakpoint.sm ? 250 : 360
          "
          color="secondary"
          class="d-flex flex-column justify-center align-center rounded-md px-4 px-sm-10"
        >
          <div class="noColor--text text-center">
            <h3 class="text-h5 text-sm-h3 text-md-h1 font-weight-black">
              Solicita una firma gratis
            </h3>
          </div>
          <div class="my-1 my-md-6">
            <v-icon
              v-for="(dot, i) in $vuetify.breakpoint.xs ? 2 : 3"
              :key="i"
              :size="$vuetify.breakpoint.xs ? 11 : 23"
              color="primary"
              class="mx-md-1"
            >
              mdi-checkbox-blank-circle
            </v-icon>
          </div>
          <v-btn
            :height="$vuetify.breakpoint.xs ? 28 : 36"
            :width="$vuetify.breakpoint.xs ? 92 : 175"
            color="primary"
            class="text-h5 text-md-h4 font-weight-black rounded-md my-2"
            aria-label="Ir a sección de Contacto"
            @click="scrollContact()"
          >
            {{ $vuetify.breakpoint.xs ? "Contratar" : "Quiero mi firma" }}
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "PricesList",
  data: () => ({
    prices: [
      { range: "1 - 50", cost: 50, cardWidth: "79%" },
      { range: "101 - 500", cost: 42, cardWidth: "82%" },
      { range: "501 - 1,000", cost: 38, cardWidth: "85%" },
      { range: "1,001 - 5,000", cost: 35, cardWidth: "88%" },
      { range: "5,001 - 10,000", cost: 30, cardWidth: "91%" },
      { range: "10,001 - 50,000", cost: 25, cardWidth: "94%" },
      { range: "50,001 - 200,000", cost: 20, cardWidth: "97%" },
      { range: "200,000+", cost: 16, cardWidth: "100%" },
    ],
  }),
  methods: {
    handleRedirect() {
      window.location.href = "https://app.nexumlegal.com.mx/login";
    },
    scrollContact() {
      this.$emit("scrollContact");
    },
  },
};
</script>

<style scoped lang="scss">
.bgImageStyles {
  background: url("../assets/background/SubscriptionPlan.png") no-repeat;
  background-size: cover !important;
}

.border-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
