<template>
  <div class="py-sm-16 pa-md-0 ma-0">
    <span class="hidden-sm-and-down">
      <div class="d-flex justify-center my-10 mt-md-16 mb-14 secondary--text">
        <h2 class="text-h1">¿Cómo funciona?</h2>
      </div>
    </span>

    <v-container
      class="d-flex flex-column flex-sm-row justify-space-between pa-5"
    >
      <div
        v-for="(item, idx) in dataArray"
        :key="idx"
        class="d-flex justify-center justify-sm-start align-start align-sm-center flex-row flex-sm-column text-start my-5 my-sm-0"
        :style="[
          $vuetify.breakpoint.xs ? { maxWidth: '100%' } : { maxWidth: '389px' },
        ]"
      >
        <img
          :style="[
            $vuetify.breakpoint.xs
              ? { maxWidth: '55px' }
              : { maxWidth: '106px' },
          ]"
          :src="item.imgSource"
          :alt="item.imgAlt"
        />
        <div class="ml-4 px-2 mt-md-9 text-sm-center secondary--text">
          <div class="mb-2 my-md-4">
            <h3 class="text-h4 text-md-h2 font-weight-bold">
              {{ item.title }}
            </h3>
          </div>
          <div class="my-md-8">
            <p class="text-body-1 text-md-subtitle-2">
              {{ item.text }}
            </p>
          </div>
        </div>
      </div>
    </v-container>
    <div class="hidden-sm-and-down">
      <img
        width="400px"
        class="d-flex align-end"
        src="@/assets/icons/HowDoesItWork/OrangeLine.png"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "how-does-it-work-03.vue",
  data: () => ({
    dataArray: [
      {
        title: "Elige",
        text: "Sube un documento a firmar a nuestra plataforma. Las partes revisan y comentan el documento para una revisión final. Una vez aceptado se manda a firma certificada.",
        imgSource: [require("@/assets/icons/HowDoesItWork/Icon01.png")],
        imgAlt: "Ícono de una mano eligiendo entre tres opciones",
      },
      {
        title: "Firma",
        text: "Firma el documento con tu firma electrónica avanzada del SAT, certificado digital, o bien, utiliza nuestro espacio de firma electrónica con rasgos biométricos. Válida tus datos e identidad al momento y firma.",
        imgSource: [require("@/assets/icons/HowDoesItWork/Icon02.png")],
        imgAlt: "Ícono de una firma",
      },
      {
        title: "Recibe",
        text: "Una vez firmado el documento, recibirás un correo electrónico con el documento certificado así como su constancia de conservación de datos emitido por un prestador de servicios de certificación autorizado por la Secretaría de Economía.",
        imgSource: [require("@/assets/icons/HowDoesItWork/Icon03.png")],
        imgAlt:
          "Ícono de una flecha hacia abajo apuntando la palma de una mano",
      },
    ],
  }),
};
</script>

<style scoped lang="scss"></style>
