<template>
  <v-card fluid class="bgImageStyles mt-0 pt-0 rounded-0" elevation="1">
    <div class="text-sm-h4 secondary--text text-center">
      <div class="mb-10 pt-16">
        <h2 class="text-h4 text-md-h1 font-weight-black">Blog</h2>
      </div>

      <div>
        <div
          class="d-flex flex-row flex-wrap justify-space-around flex-wrap px-6"
        >
          <v-card
            v-for="item in cardDataArray"
            :key="item.title"
            color="grayColor"
            background-color="grey lighten-1"
            class="ma-5 pa-5 mb-10 rounded-md"
            elevation="3"
            :style="[
              $vuetify.breakpoint.xs
                ? { width: '300px' }
                : $vuetify.breakpoint.sm
                ? { width: '350px' }
                : { width: '450px' },
            ]"
          >
            <div>
              <img
                :src="item.imgSource"
                :style="[
                  $vuetify.breakpoint.xs
                    ? { width: '230px' }
                    : $vuetify.breakpoint.sm
                    ? { width: '310px' }
                    : { width: '410px' },
                ]"
                :alt="item.imgAlt"
              />
            </div>
            <div class="secondary--text my-2">
              <h3 class="font-weight-bold"
                :class="
                  $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                    ? 'text-h4'
                    : 'text-subtitle-0'
                "
              >
                {{ item.title }}
              </h3>
            </div>
            <div
              class="secondary--text my-2 text-body-1 text-md-subtitle-1 text-start"
            >
              {{ item.comment }}
            </div>
            <div class="d-flex justify-space-between">
              <div class="secondary--text my-2 text-body-1 text-start">
                {{ item.date }}
              </div>
              <div>
                <v-btn
                  outlined
                  color="secondary"
                  height="35px"
                  disabled
                  aria-disabled="true"
                  class="rounded-md text-body-1 text-md-h4 font-weight-bold"
                >
                  ¡Próximamente!
                </v-btn>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "blog-12.vue",
  data: () => ({
    cardDataArray: [
      {
        imgSource: [require("@/assets/icons/Blog/PhoneImg.png")],
        imgAlt: "Persona firmando un documento digital a través de un celular",

        title: "Beneficios de la firma electrónica",
        comment:
          "La firma electrónica en México no es un tema o reciente, tomando en cuenta que la misma se encuentra contemplada en...",
        date: "24 agosto, 2021",
        sourceUrl: "",
      },
      {
        imgSource: [require("@/assets/icons/Blog/SignImg.png")],
        imgAlt:
          "Persona firmando un documento con un lápiz digital a través de una tablet",
        title: "La firma electrónica avanzada en contratos",
        comment:
          "A pesar de que nos encontramos en el siglo XXI, la época de la digitalización, aún nos encontramos realizando la...",
        date: "12 mayo, 2021",
        sourceUrl: "",
      },
    ],
  }),
};
</script>

<style scoped lang="scss">
.bgImageStyles {
  background: url("../assets/background/BlogBackground.png") no-repeat;
  background-size: cover !important;
}
</style>
