var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"bgImageStyles mt-0 pt-0 rounded-0",attrs:{"fluid":"","elevation":"1"}},[_c('div',{staticClass:"text-sm-h4 secondary--text text-center"},[_c('div',{staticClass:"mb-10 pt-16"},[_c('h2',{staticClass:"text-h4 text-md-h1 font-weight-black"},[_vm._v("Blog")])]),_c('div',[_c('div',{staticClass:"d-flex flex-row flex-wrap justify-space-around flex-wrap px-6"},_vm._l((_vm.cardDataArray),function(item){return _c('v-card',{key:item.title,staticClass:"ma-5 pa-5 mb-10 rounded-md",style:([
            _vm.$vuetify.breakpoint.xs
              ? { width: '300px' }
              : _vm.$vuetify.breakpoint.sm
              ? { width: '350px' }
              : { width: '450px' } ]),attrs:{"color":"grayColor","background-color":"grey lighten-1","elevation":"3"}},[_c('div',[_c('img',{style:([
                _vm.$vuetify.breakpoint.xs
                  ? { width: '230px' }
                  : _vm.$vuetify.breakpoint.sm
                  ? { width: '310px' }
                  : { width: '410px' } ]),attrs:{"src":item.imgSource,"alt":item.imgAlt}})]),_c('div',{staticClass:"secondary--text my-2"},[_c('h3',{staticClass:"font-weight-bold",class:_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
                  ? 'text-h4'
                  : 'text-subtitle-0'},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('div',{staticClass:"secondary--text my-2 text-body-1 text-md-subtitle-1 text-start"},[_vm._v(" "+_vm._s(item.comment)+" ")]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"secondary--text my-2 text-body-1 text-start"},[_vm._v(" "+_vm._s(item.date)+" ")]),_c('div',[_c('v-btn',{staticClass:"rounded-md text-body-1 text-md-h4 font-weight-bold",attrs:{"outlined":"","color":"secondary","height":"35px","disabled":"","aria-disabled":"true"}},[_vm._v(" ¡Próximamente! ")])],1)])])}),1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }