<template>
  <div>
    <div class="mt-1 mt-sm-2 mb-4 rotateimg180">
      <v-card
        flat
        color="primary"
        :width="
          $vuetify.breakpoint.xs ? 50 : $vuetify.breakpoint.sm ? 120 : 257
        "
        :height="$vuetify.breakpoint.xs ? 28 : $vuetify.breakpoint.sm ? 60 : 94"
        class="roundedCard borderRound"
      />
    </div>

    <div class="d-flex align-center justify-center mx-md-16 px-8 px-md-16">
      <img
        :height="
          $vuetify.breakpoint.xs ? 40 : $vuetify.breakpoint.sm ? 60 : 120
        "
        src="@/assets/icons/LegalDocument/check.png"
        alt="Ícono de una marca de verificación"
      />

      <h2 
        class="secondary--text font-weight-black px-6"
        :class="
          $vuetify.breakpoint.xs
            ? 'text-h5'
            : $vuetify.breakpoint.sm
            ? 'text-h4'
            : $vuetify.breakpoint.md
            ? 'text-h3'
            : $vuetify.breakpoint.lg
            ? 'text-h2'
            : 'text-h1'
        "
        :style="
          $vuetify.breakpoint.xs
            ? { maxWidth: '80%' }
            : $vuetify.breakpoint.sm
            ? { maxWidth: '70%' }
            : $vuetify.breakpoint.md
            ? { maxWidth: '60%' }
            : { maxWidth: '50%' }
        ">
        Cada documento firmado con <span class="primary--text">Nexum</span> cuenta con una total validez legal y certeza jurídica.
      </h2>
    </div>

    <div
      class="d-flex align-center mt-8 mt-sm-16 mb-4 mb-sm-5 pt-md-16 pb-sm-2 pb-md-6"
    >
      <v-card
        flat
        color="primary"
        :width="
          $vuetify.breakpoint.xs ? 60 : $vuetify.breakpoint.sm ? 150 : '17%'
        "
        :height="$vuetify.breakpoint.xs ? 28 : $vuetify.breakpoint.sm ? 60 : 94"
        :style="
          !$vuetify.breakpoint.xs &&
          !$vuetify.breakpoint.sm && { position: 'absolute' }
        "
        class="roundedCard borderRound"
      />
      <v-row class="justify-end justify-md-center">
        <v-col
          cols="3"
          class="d-flex justify-end mr-sm-8 mr-xl-16 pr-5 pr-sm-8"
        >
          <img
            :height="
              $vuetify.breakpoint.xs ? 22 : $vuetify.breakpoint.sm ? 45 : 80
            "
            src="@/assets/icons/LegalDocument/se-logo.png"
            alt="Logo de Secretaría de Economía"
          />
        </v-col>
        <v-col
          cols="3"
          class="d-flex justify-start ml-sm-8 ml-xl-16 pl-5 pl-sm-10"
        >
          <img
            :height="
              $vuetify.breakpoint.xs ? 22 : $vuetify.breakpoint.sm ? 45 : 93
            "
            src="@/assets/icons/LegalDocument/nom-logo.png"
            alt="Logo de Normas Oficiales Mexicanas"
          />
        </v-col>
      </v-row>
    </div>
    <div class="pt-md-9">
      <v-card
        flat
        color="primary"
        :width="
          $vuetify.breakpoint.xs ? 130 : $vuetify.breakpoint.sm ? 300 : 655
        "
        :height="$vuetify.breakpoint.xs ? 28 : $vuetify.breakpoint.sm ? 60 : 94"
        class="d-flex align-center roundedCard borderRound"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "legal-document-10.vue",
};
</script>

<style scoped lang="scss">
.rotateimg180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.borderRound {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
</style>
