<template>
  <v-app>
    <v-main>
      <Navbar
        @scrollHowItWorks="handleScroll('howItWorks')"
        @scrollPrices="handleScroll('prices')"
        @scrollContact="handleScroll('contact')"
        @scrollBlog="handleScroll('blog')"
        @scrollTop="handleScroll('top')"
      />
      <router-view />
      <Footer-13 />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer13 from "./components/Footer13.vue";
export default {
  name: "App",
  components: {
    Navbar,
    Footer13,
  },
  methods: {
    async handleScroll(idName) {
      if (this.$router.currentRoute.name === "privacy") {
        await this.$router.push({ name: "home" });
      }
      let element = document.getElementById(idName);
      let headerOffset = 80;
      if (idName === "howItWorks") {
        headerOffset = 120;
      }
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
  },
};
</script>
