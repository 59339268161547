<template>
  <div id="top">
    <div class="fill-height">
      <v-card height="80px"></v-card>

      <!-- Hero Img -->
      <Hero-section-01 @scrollContact="handleScroll('contact')" />

      <!-- Orange Section -->
      <Document-sign-02 />

      <!-- How does it work? -->
      <How-does-it-work-03 id="howItWorks" />

      <!-- Video sign -->
      <Video-section-04 />

      <!-- Benefits -->
      <Benefits-component-05 />

      <!-- Subscription plan -->
      <Prices-list-06 id="prices" @scrollContact="handleScroll('contact')" />

      <!-- Businesses working with Nexum -->
      <Nexum-businesses-08 />

      <!-- Reviews -->
      <Reviews-09 />

      <!-- Legal Document -->
      <Legal-document-10 />

      <!-- Send form -->
      <Send-form-11 id="contact" />

      <!-- Blog -->
      <Blog-12 id="blog" />
    </div>
  </div>
</template>

<script>
import HeroSection01 from "@/components/01HeroSection.vue";
import DocumentSign02 from "@/components/02DocumentSign.vue";
import HowDoesItWork03 from "@/components/03HowDoesItWork.vue";
import VideoSection04 from "@/components/04VideoSection.vue";
import BenefitsComponent05 from "@/components/05BenefitsComponent.vue";
import PricesList06 from "@/components/06PricesList.vue";
import NexumBusinesses08 from "@/components/08NexumBusinesses.vue";
import Reviews09 from "@/components/09Reviews.vue";
import LegalDocument10 from "@/components/10LegalDocument.vue";
import SendForm11 from "@/components/11SendForm.vue";
import Blog12 from "@/components/12Blog.vue";

export default {
  components: {
    HeroSection01,
    DocumentSign02,
    HowDoesItWork03,
    VideoSection04,
    BenefitsComponent05,
    PricesList06,
    NexumBusinesses08,
    Reviews09,
    LegalDocument10,
    SendForm11,
    Blog12,
  },
  data: () => ({
    //
  }),
  methods: {
    handleScroll(idName) {
      let element = document.getElementById(idName);
      let headerOffset = 80;
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
  },
};
</script>
