export const maxDigitsPhone = 10;

export const nameRules = [
  (v) => !!v || "Nombre requerido",
  (v) => (v && v.length >= 3) || "Al menos 3 caracteres",
];
export const emailRules = [
  (v) => !!v || "Correo requerido",
  (v) => /.+@.+/.test(v) || "El Correo debe ser valido",
];
export const phoneRules = [
  (v) => !!v || "Telefono requerido",
  (v) => /^\d+$/.test(v) || "Solo digitos",
  (v) => (v && v.length == maxDigitsPhone) || "10 digitos",
];
export const commentRules = [(v) => !!v || "Comentarios requeridos"];
