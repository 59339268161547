<template>
  <div
    class="bgStyleOrange d-flex justify-center align-center"
    :style="[
      $vuetify.breakpoint.xs
        ? { minHeight: '15vh' }
        : $vuetify.breakpoint.sm
        ? { minHeight: '20vh' }
        : { minHeight: '40vh' },
    ]"
  >
    <div
      class="text-center text-body-2 py-8 py-sm-16 px-8"
    >
      <h2 class="noColor--text my-md-13 text-h5 text-sm-h3 text-md-h2 text-lg-h1 font-weight-bold">
        Firma tus documentos con validez legal y certeza.
        <br />
        Ya sea con tu firma autográfa o firma electrónica,
        <br />
        <span class="secondary--text">
        y acelera la operación de tu empresa.
        </span>
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "Document-Sign-02",
  data: () => ({}),
};
</script>

<style scoped lang="scss">
.bgStyleOrange {
  background: url("../assets/background/OrangeBackground.png") no-repeat center
    center;
  background-size: cover !important;
}
</style>
