<template>
  <div
    class="bgImageStyles d-flex align-center justify-end"
    :style="[
      $vuetify.breakpoint.xs
        ? { minHeight: '40vh' }
        : $vuetify.breakpoint.sm
        ? { minHeight: '60vh' }
        : { minHeight: '100vh' },
    ]"
    title="Usuario de Nexum firmando un documento digital a través de una tableta"
  >
    <div class="d-flex align-center mr-2 mr-sm-16">
      <div
        class="d-flex flex-column align-end align-md-start mr-4 mr-md-13 pb-10 pb-sm-16"
      >
        <h1 class="text-h4 text-sm-h2 text-md-h1 font-weight-black secondary--text">
          Firma electrónica
          <br />
          con validez jurídica.
          <br />
          <span class="primary--text"> Donde sea, cuando sea.</span>
        </h1>
        <v-btn
          small
          outlined
          color="secondary"
          width="160px"
          height="40px"
          :ripple="false"
          class="rounded-md text-caption text-h4 pa-md-3 mt-8 mt-sm-3 font-weight-black"
          aria-label="Ir sección de contacto"
          @click="scrollContact()"
        >
          Agenda tu demo
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hero-section-01",
  data: () => ({}),
  computed: {},
  methods: {
    scrollContact() {
      this.$emit("scrollContact");
    },
  },
};
</script>

<style scoped lang="scss">
.bgImageStyles {
  background: url("../assets/background/HeroIpad.png") no-repeat center center;
  background-size: cover !important;
}
</style>
