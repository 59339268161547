<template>
  <div>
    <v-row class="pa-0 ma-0">
      <v-col
        cols="12"
        md="6"
        class="bgImageStyles"
        title="Persona tecleando en una computadora portátil"
      >
        <div class="hidden-md-and-up noColor--text px-16">
          <div class="mb-10 pt-16 text-center">
            <h2 class="text-h4 text-md-h1 mx-auto font-weight-bold" style="max-width: 80%;">
              ¿Estás listo para comenzar a ahorrar <span class="primary--text">tiempo, dinero y esfuerzo</span>?
            </h2>
          </div>
          <v-form
            v-model="isFormValid"
            action="https://formsubmit.co/jorge.acosta@nexumlegal.com.mx"
            method="POST"
          >
            <div>
              <label
                for="userName"
                class="text-h5 text-md-subtitle-1 text-start"
                >Nombre de usuario
              </label>
              <v-text-field
                id="userName"
                placeholder="¿Cuál es tu nombre?"
                outlined
                dense
                dark
                :rules="nameRules"
                color="noColor"
                name="Nombre"
              />
            </div>
            <div>
              <label
                for="userEmail"
                class="text-h5 text-md-subtitle-1 text-start"
              >
                Correo electrónico
              </label>
              <v-text-field
                id="userEmail"
                placeholder="¿Cuál es tu correo electrónico?"
                outlined
                dense
                dark
                :rules="emailRules"
                color="noColor"
                name="Correo electrónico"
              />
            </div>
            <div>
              <label
                for="userPhone"
                class="text-h5 text-md-subtitle-1 text-start"
              >
                Celular
              </label>
              <v-text-field
                id="userPhone"
                placeholder="¿Cuál es tu número?"
                outlined
                dense
                dark
                :rules="phoneRules"
                color="noColor"
                name="Celular"
              />
            </div>
            <div>
              <label
                for="userComments"
                class="text-h5 text-md-subtitle-1 text-start"
              >
                ¿Cómo podemos ayudarte?
              </label>
              <v-textarea
                id="userComments"
                outlined
                dense
                dark
                :rules="commentRules"
                color="noColor"
                placeholder="Escribir comentarios"
                name="Comentarios"
              />
            </div>
            <div class="d-flex justify-end">
              <v-btn
                :disabled="!isFormValid"
                class="text-h5 rounded-md my-2 noColor-text mb-16"
                color="primary"
                type="submit"
                :aria-label="
                  isFormValid ? altTextButton.enabled : altTextButton.disabled
                "
              >
                <b>Enviar mensaje</b>
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-col>
      <!-- Desktop -->
      <v-col cols="12" md="6" class="hidden-sm-and-down bgColor">
        <div class="text-md-h1 text-sm-h4 noColor--text px-0">
          <div class="mb-10 pt-16 text-center">
            <h2 class="text-md-h1 mx-auto" style="max-width: 90%;">
              ¿Estás listo para comenzar a ahorrar <span class="primary--text">tiempo, dinero y esfuerzo</span>?
            </h2>
          </div>
          <v-form
            v-model="isFormValid"
            action="https://formsubmit.co/jorge.acosta@nexumlegal.com.mx"
            method="POST"
          >
            <div>
              <label for="userName" class="text-h3 font-weight-bold text-start">
                Nombre de usuario
              </label>
              <v-text-field
                id="userName"
                placeholder="¿Cuál es tu nombre?"
                outlined
                dense
                dark
                :rules="nameRules"
                color="noColor"
                name="Nombre"
              />
            </div>
            <div>
              <label
                for="userEmail"
                class="text-h3 font-weight-bold text-start"
              >
                Correo electrónico
              </label>
              <v-text-field
                id="userEmail"
                placeholder="¿Cuál es tu correo electrónico?"
                outlined
                dense
                dark
                :rules="emailRules"
                color="noColor"
                name="Correo electrónico"
              />
            </div>
            <div>
              <label
                for="userPhone"
                class="text-h3 font-weight-bold text-start"
              >
                Celular
              </label>
              <v-text-field
                id="userPhone"
                placeholder="¿Cuál es tu número?"
                outlined
                dense
                dark
                :rules="phoneRules"
                color="noColor"
                name="Celular"
              />
            </div>
            <div>
              <label
                for="userComments"
                class="text-h3 font-weight-bold text-start"
              >
                ¿Cómo podemos ayudarte?
              </label>
              <v-textarea
                id="userComments"
                outlined
                dense
                dark
                :rules="commentRules"
                color="noColor"
                placeholder="Escribir comentarios"
                name="Comentarios"
              />
            </div>
            <div class="d-flex justify-end">
              <v-btn
                :disabled="!isFormValid"
                class="rounded-md my-2 noColor-text mb-16 text-h4 font-weight-bold"
                color="primary"
                type="submit"
                :aria-label="
                  isFormValid ? altTextButton.enabled : altTextButton.disabled
                "
              >
                Enviar mensaje
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  nameRules,
  emailRules,
  phoneRules,
  commentRules,
} from "../utils/rules";
export default {
  name: "send-form-11",
  data: () => ({
    isFormValid: false,
    nameRules: nameRules,
    phoneRules: phoneRules,
    emailRules: emailRules,
    commentRules: commentRules,
    altTextButton: {
      enabled: "Enviar formulario",
      disabled:
        "Botón para enviar formulario, rellena los campos para poder enviar el formulario",
    },
  }),
};
</script>

<style scoped lang="scss">
.bgImageStyles {
  background: url("../assets/background/LaptopGuy.png") no-repeat center center;
  background-size: cover !important;
}

.bgColor {
  background-color: #011d3f;
  background-size: cover !important;
}

.theme--light.v-btn.v-btn--disabled {
  color: #f77417 !important;
}
</style>
