var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bgImageStyles"},[_vm._m(0),_c('div',{staticClass:"d-flex justify-start"},[_c('div',{staticClass:"d-flex flex-column pl-10"},[_c('div',{staticClass:"noColor--text text-sm-h4 text-md-h1 text-start"},[_c('v-icon',{staticClass:"noColor--text text-h4 text-md-h1 pb-2 font-weight-bold",attrs:{"alt":"Ícono de Facebook"}},[_vm._v(" mdi-facebook ")]),_c('label',[_vm._v(" /nexum.firma.electronica ")])],1),_c('div',{staticClass:"noColor--text text-sm-h4 text-md-h1"},[_c('v-icon',{staticClass:"noColor--text text-h4 text-md-h1 font-weight-bold",attrs:{"alt":"Ícono de Instagram"}},[_vm._v(" mdi-instagram ")]),_c('label',[_vm._v(" /nexum.legal ")])],1)])]),_c('div',{staticClass:"d-flex justify-end py-16"},[_c('v-card',{staticClass:"d-flex align-center roundedCard",style:([
        _vm.$vuetify.breakpoint.xs
          ? { width: '200px', height: '70px' }
          : _vm.$vuetify.breakpoint.sm
          ? { width: '350px', height: '100px' }
          : { width: '470px', height: '130px' } ]),attrs:{"color":"noColor","height":"130px","width":"470px"}},[_c('img',{staticClass:"ml-6 ml-sm-10 ml-md-16 rounded-0",style:([
          _vm.$vuetify.breakpoint.xs
            ? { width: '150px' }
            : _vm.$vuetify.breakpoint.sm
            ? { maxWidth: '250px' }
            : { width: '300px' } ]),attrs:{"src":[require('@/assets/icons/LogoNexum.png')],"alt":"Logo de Nexum"}})])],1),_c('div',{staticClass:"noColor--text ml-10 pb-15"},[_c('label',[_vm._v("© 2022 - 2023")]),_c('label',{staticClass:"cursor-pointer ml-8",attrs:{"alt":"Ir al aviso de seguridad de Nexum"},on:{"click":_vm.handlePrivacyNoticeRoute}},[_vm._v(" Aviso de seguridad ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-start text-h4 text-md-h1 text-start mb-10 pt-16 pl-10"},[_c('label',[_c('b',{staticClass:"secondary--text font-weight-black"},[_vm._v("Firma electrónica "),_c('br'),_vm._v(" con validez jurídica. "),_c('br'),_c('span',{staticClass:"noColor--text"},[_vm._v("Donde sea, cuando sea.")])])])])}]

export { render, staticRenderFns }