<template>
  <v-container fluid class="bgImageStyles">
    <v-row class="pa-0 pa-sm-5 mt-0 justify-center">
      <v-col cols="8" class="pa-0">
        <div
          class="secondary--text text-center d-flex justify-center mt-2 mt-md-10 mb-0 pb-0"
        >
          <h2 class="text-h4 text-md-h1 font-weight-black">
            Así de sencillo es firmar tus documentos con <span class="primary--text">Nexum.</span>
          </h2>
        </div>
      </v-col>
      <v-col cols="10">
        <video
          controls
          ref="videoPlayer"
          class="mb-3 mt-md-12 mb-md-15"
          width="100%"
          :src="require('../assets/video/NexumVideo.mp4')"
          :style="[
            $vuetify.breakpoint.xs
              ? { height: '200px' }
              : $vuetify.breakpoint.sm
              ? { height: '300px' }
              : { height: '555px' },
          ]"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "video-section-03.vue",
  data: () => ({}),
  computed: {},
  methods: {
    play() {
      this.$refs.videoPlayer.play();
    },
    pause() {
      this.$refs.videoPlayer.pause();
    },
    stop() {
      const { videoPlayer } = this.$refs;
      videoPlayer.pause();
      videoPlayer.currentTime = 0;
    },
    setSpeed(speed) {
      this.$refs.videoPlayer.playbackRate = speed;
    },
  },
};
</script>

<style scoped lang="scss">
.bgImageStyles {
  background: url("../assets/background/VideoSection.png") no-repeat;
  background-size: cover !important;
}
</style>
