<template>
  <v-container fluid class="bgImageStyles">
    <v-row class="pa-0 pa-md-5 mt-0">
      <v-col cols="1" />
      <v-col cols="10" class="pa-0">
        <div
          class="secondary--text text-h4 text-md-h1 text-center d-flex justify-center mt-10 mb-4 pb-0 font-weight-bold"
        >
          <b>
            Empresas que ya confían en
            <span class="primary--text">Nexum.</span>
          </b>
        </div>
      </v-col>
      <v-col cols="1" />

      <!-- Desktop -->
      <v-col class="hidden-sm-and-down">
        <v-container
          class="d-flex flex-column flex-sm-row justify-space-around mb-5"
        >
          <div
            v-for="(items, idx) in dataArray"
            :key="idx"
            class="d-flex align-center align-sm-center flex-row my-5 my-sm-0"
          >
            <img width="150px" :src="items" />
          </div>
        </v-container>
      </v-col>

      <!-- Mobile -->
      <v-col cols="12" class="hidden-md-and-up">
        <v-slide-group center-active show-arrows>
          <v-slide-item v-for="(items, idx) in dataArray" :key="idx + 1">
            <v-card class="ma-4" height="200" width="150px" elevation="0">
              <v-row class="fill-height" align="center" justify="center">
                <v-scale-transition>
                  <img width="150px" :src="items" />
                </v-scale-transition>
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-col>
      <v-col cols="12" class="hidden-md-and-up d-flex justify-center mt-0 mt-0">
        <div class="mb-5 hidden-md-and-up">
          <label class="text-body-1">
            Desliza para ver más
          </label>
          <v-icon class="secondary--text">mdi-menu-right</v-icon>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "nexum-businesses-08.vue",
  data: () => ({
    dataArray: [
      [require("@/assets/icons/NexumBusinesses/Zuma.png")],
      [require("@/assets/icons/NexumBusinesses/Centro.png")],
      [require("@/assets/icons/NexumBusinesses/IlumLogo.png")],
      [require("@/assets/icons/NexumBusinesses/TencoLogo.png")],
      [require("@/assets/icons/NexumBusinesses/PID.png")],
      [require("@/assets/icons/NexumBusinesses/Trasoto.png")],
    ],
  }),
};
</script>
