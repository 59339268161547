<template>
  <div>
    <v-app-bar
      elevate-on-scroll
      flat
      fixed
      dense
      color="noColor"
      class="px-6"
      elevation="4"
      height="80px"
    >
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        color="secondary"
        aria-label="Abrir el menú"
        @click="handleDrawer()"
      >
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>

      <v-btn
        depressed
        dark
        :ripple="false"
        class="font-weight-black text-overline px-0"
        color="noColor"
        aria-label="Ir a Inicio"
      >
        <v-img
          width="223px"
          class="hidden-sm-and-down"
          aria-label="Ir a inicio"
          src="../assets/icons/LogoNexum.png"
          @click="redirictToHomePage()"
        />
        <v-img
          width="83px"
          class="hidden-md-and-up"
          aria-label="Ir a inicio"
          src="../assets/icons/LogoNexumMobile.png"
          @click="redirictToHomePage()"
        />
      </v-btn>
      <v-spacer />
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          text
          active-class="primary"
          depressed
          dark
          :ripple="false"
          color="secondary"
          router
          class="text-none text-h3 font-weight-bold btnList"
          aria-label="Ir a sección de ¿Cómo funciona?"
          @click="scrollHowItWorks()"
        >
          <span class="navbartitle">Cómo funciona</span>
        </v-btn>
        <v-btn
          text
          active-class="primary"
          depressed
          dark
          :ripple="false"
          color="secondary"
          router
          class="text-none text-h3 font-weight-bold btnList"
          aria-label="Ir a sección de Precios"
          @click="scrollPrices()"
        >
          <span class="navbartitle">Precios</span>
        </v-btn>
        <v-btn
          text
          active-class="primary"
          depressed
          dark
          :ripple="false"
          color="secondary"
          router
          class="text-none text-h3 font-weight-bold btnList"
          aria-label="Ir a sección de Contacto"
          @click="scrollContact()"
        >
          <span class="navbartitle">Contacto</span>
        </v-btn>
        <v-btn
          text
          active-class="primary"
          depressed
          dark
          :ripple="false"
          color="secondary"
          router
          class="text-none text-h3 font-weight-bold btnList"
          aria-label="Ir a sección de Blog"
          @click="scrollBlog()"
        >
          <span class="navbartitle">Blog</span>
        </v-btn>
      </v-toolbar-items>

      <v-btn
        style="overflow-x: hidden"
        color="primary"
        :ripple="false"
        class="rounded-lg ml-5"
        :class="{
          'text-none text-h6 text-md-h4 font-weight-bold': true,
        }"
        aria-label="Ir a inicio de sesión de app.nexumlegal.com.mx"
        @click="handleRedirect()"
      >
        <span> Iniciar sesión</span>
      </v-btn>
    </v-app-bar>

    <!-- MOBILE -->

    <transition v-if="drawer">
      <v-card width="100%" class="d-flex flex-column transitionStyle">
        <v-btn
          text
          active-class="primary"
          depressed
          dark
          :ripple="false"
          color="secondary"
          height="60px"
          router
          class="text-none text-h5 font-weight-bold btnListMobile py-3"
          aria-label="Ir a sección de ¿Cómo funciona?"
          @click="scrollHowItWorks()"
        >
          <span class="navbartitle">Cómo funciona</span>
        </v-btn>
        <v-btn
          text
          active-class="primary"
          depressed
          dark
          :ripple="false"
          color="secondary"
          height="60px"
          class="text-none text-h5 font-weight-bold btnListMobile py-3"
          aria-label="Ir a sección de Precios"
          router
          @click="scrollPrices()"
        >
          <span class="navbartitle">Precios</span>
        </v-btn>
        <v-btn
          text
          active-class="primary"
          depressed
          dark
          :ripple="false"
          color="secondary"
          height="60px"
          router
          class="text-none text-h5 font-weight-bold btnListMobile py-3"
          aria-label="Ir a sección de Contacto"
          @click="scrollContact()"
        >
          <span class="navbartitle">Contacto</span>
        </v-btn>
        <v-btn
          width="100%"
          text
          active-class="primary"
          depressed
          dark
          :ripple="false"
          color="secondary"
          height="60px"
          router
          class="text-none text-h5 font-weight-bold btnListMobile py-3"
          aria-label="Ir a sección de Blog"
          @click="scrollBlog()"
        >
          <span class="navbartitle">Blog</span>
        </v-btn>
      </v-card>
    </transition>
  </div>
</template>

<script>
export default {
  name: "nav-bar",
  data: () => ({
    drawer: false,
  }),
  computed: {},
  methods: {
    handleDrawer() {
      this.drawer = !this.drawer;
    },
    handleRedirect() {
      window.location.href = "https://app.nexumlegal.com.mx/login";
    },
    redirictToHomePage() {
      if (this.$router.currentRoute.path !== "/") {
        this.$router.push("/");
      }
      this.drawer = false;
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    scrollHowItWorks() {
      this.drawer = false;
      this.$emit("scrollHowItWorks");
    },
    scrollPrices() {
      this.drawer = false;
      this.$emit("scrollPrices");
    },
    scrollContact() {
      this.drawer = false;
      this.$emit("scrollContact");
    },
    scrollBlog() {
      this.drawer = false;
      this.$emit("scrollBlog");
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn {
  span {
    font-size: 1.1rem !important;
  }
  &:hover {
    .navbartitle {
      transform: scale(1.01);
    }
    .navbaricon {
      transform: scale(1.04);
    }
  }
}

.transitionStyle {
  position: fixed;
  top: 80px;
  z-index: 3;
}

.btn-background-transparent::before {
  background-color: transparent !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.btnListMobile:hover {
  text-decoration: underline;
  text-underline-offset: 10px;
  text-decoration-color: #f77417;
  text-decoration-thickness: 3px;
}

.btnList::before {
  background-color: transparent !important;
}

.btnList {
  display: inline-block;
  position: relative;
  color: #f77417;
}

.btnList:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 20px;
  left: 0;
  background-color: #f77417;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.btnList:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
</style>
